import { useEffect, useState } from 'react';
import styles from './lead-activity-log.module.scss';
import { useTranslation } from 'react-i18next';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { Feedback } from '../../definitions/interfaces/deals.interface';

export function LeadActivityLog(
  props: Readonly<{ pipelineSourceId: string; refetchActivityLog: boolean }>
) {
  const { t } = useTranslation();
  const [feedbackList, setFeedbackList] = useState<Feedback[]>();

  useEffect(() => {
    DealUtils.listFeedback(props.pipelineSourceId)
      .then((res) => {
        setFeedbackList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.pipelineSourceId, props.refetchActivityLog]);

  return (
    <div className={styles['activity-log']}>
      <p className={styles['title']}>{t('activityLog')}</p>
      <table>
        <tr>
          <th>{t('action')}</th>
          <th>{t('date')}</th>
        </tr>
        <tbody>
          {feedbackList?.map((feedback) => (
            <tr key={feedback.feedback_source_id}>
              <td>
                <p>{feedback.feedback}</p>
              </td>
              <td>
                <p>{feedback.feedback_title}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeadActivityLog;
