import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './my-deals.module.scss';
import { Loader } from '@orascom/common-components';
import { DealInterface } from '../../definitions/interfaces/deals.interface';
import plusIcon from '../../assets/icons/plus--white.svg';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { CommonEventParameters } from '@orascom/utils';
import { analyticsCreateLeadCustomEvent } from '../../utils/analytics-events';
import { ROUTES } from '../../api/routes';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { format } from 'date-fns';

export function MyDeals() {
  const { t } = useTranslation();
  // For activeTab (1 = digital leads) (2 = indirect leads) (3 = direct leads) (4 = no answer leads) (5 = all leads)
  const tabs = { digital: 1, indirect: 2, direct: 3, noAnswer: 4, all: 5 };
  const [activeTab, setActiveTab] = useState(5);
  const tlsCommentReplacmentHeader: Record<number, string> = {
    1: 'tlsComment',
    2: 'ambassadorName',
    3: 'createdOn',
    4: 'followUpDate',
    5: 'leadSource',
  };
  const [deals, setDeals] = useState<DealInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = deals.slice(indexOfFirstItem, indexOfLastItem);
  const [tableLoading, setTableLoading] = useState(true);
  const location = useLocation();

  const handleDateFormat = (value: string) => {
    const date = new Date(value);
    const formattedDate = format(date, 'dd MMMM yyyy');

    return formattedDate;
  };

  const handleTabBtn = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
      setCurrentPage(1);
      setTableLoading(true);
    }
  };

  // Todo: all this will be dynamic after integration
  const analyticsEventParams: CommonEventParameters = {
    userId: '1',
    timestamp: Date.now().toString(),
    portal: 'Sales Man',
    pageName: location.pathname,
  };

  useEffect(() => {
    DealUtils.getLeads(activeTab)
      .then((res) => {
        setDeals(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [activeTab]);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myLeads')}</h1>
      <div className={styles['tabs']}>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.all)}
          className={`${styles['tab']} ${
            activeTab === tabs.all && styles['tab--active']
          }`}
        >
          {t('allLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => {
            handleTabBtn(tabs.digital);
          }}
          className={`${styles['tab']} ${
            activeTab === tabs.digital && styles['tab--active']
          }`}
        >
          {t('digitalLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.indirect)}
          className={`${styles['tab']} ${
            activeTab === tabs.indirect && styles['tab--active']
          }`}
        >
          {t('indirectLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.direct)}
          className={`${styles['tab']} ${
            activeTab === tabs.direct && styles['tab--active']
          }`}
        >
          {t('directLeads')}
        </button>
        <button
          disabled={tableLoading ? true : false}
          onClick={() => handleTabBtn(tabs.noAnswer)}
          className={`${styles['tab']} ${
            activeTab === tabs.noAnswer && styles['tab--active']
          }`}
        >
          {t('noAnswerLeads')}
        </button>
      </div>
      <div className={styles['sub-header']}>
        <Link
          to={ROUTES['NewDeal'].path}
          className={styles['deal-anchor']}
          onClick={() => analyticsCreateLeadCustomEvent(analyticsEventParams)}
        >
          <img src={plusIcon} alt="plus-icon" />
          {t('newLead')}
        </Link>
      </div>
      <div className={styles['table']}>
        {tableLoading ? (
          <Loader />
        ) : currentItems.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('mobileNumber')}</th>
                <th>{t('owner')}</th>
                <th>{t('leadExpDate')}</th>
                <th>{t('leadExpStatus')}</th>
                <th>{t('channel')}</th>
                <th>{t('lastEngDate')}</th>
                {activeTab === tabs.indirect && <th>{t('broker')}</th>}
                <th>{t(tlsCommentReplacmentHeader[activeTab])}</th>
                <th>{t('feedback')}</th>
                <th>{t('action')}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((deal) => {
                const tlsCommentReplacmentBody: Record<number, string | null> =
                  {
                    1: deal.tlscsr_comment,
                    2: deal.ambassador,
                    3: handleDateFormat(deal.source_created_at),
                    4: deal.followup_date
                      ? handleDateFormat(deal.followup_date)
                      : '-',
                    5: deal.lead_source,
                  };

                return (
                  <tr key={deal.lead_source_id}>
                    <td style={{ whiteSpace: 'normal', minWidth: '160px' }}>
                      {deal.name}
                    </td>
                    <td>{deal.phone}</td>
                    <td>{deal.owner}</td>
                    <td>{deal.lead_expiration_date}</td>
                    <td>{deal.lead_expiration_status}</td>
                    <td>{deal.channel}</td>
                    <td>{deal.last_engagement_date}</td>
                    {activeTab === tabs.indirect && <td>{deal.broker_name}</td>}
                    <td>{tlsCommentReplacmentBody[activeTab]}</td>
                    <td style={{ minWidth: '120px' }}>{deal.sales_feedback}</td>
                    <td>
                      <div className={styles['last-column']}>
                        <Link
                          className={styles['deal-details']}
                          to={ROUTES['DealDetails'].getPath?.(
                            deal.lead_source_id
                          )}
                        >
                          <span className="anchor">{t('leadDetails')}</span>
                          <img src={arrowIcon} alt="" role="presentation" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>{t('noLeadsToDisplay')}</p>
        )}
      </div>
      {currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > deals.length ? deals.length : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{deals.length} </span>
            {t('sales')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={deals.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MyDeals;
