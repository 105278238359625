import styles from './deal-scheduled-meetings.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import Calendar from 'react-calendar';
import {
  Deal as DealUtils,
  ObjectionOptions,
  ObjectionValues,
} from '../../utils/deal.utils';
import {
  DealStatusEnum,
  Meetings,
  UpdatePipeline,
  UpdatePipelineInputInterface,
} from '../../definitions/interfaces/deals.interface';
import { Loader, NavyButton, SelectDropdown } from '@orascom/common-components';
import PlusIcon from '../../assets/icons/plus--white.svg';
import { Value } from '../initial-meeting/initial-meeting';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { OptionValue } from '@orascom/api-interfaces';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface ScheduledMeetingsProps {
  pipelineSourceId: string;
  destinationSlug: string;
  dealStatus: DealStatusEnum;
  setRefetchDeal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDeal: boolean;
}
export function ScheduledMeetings(props: Readonly<ScheduledMeetingsProps>) {
  const { dealId } = useParams();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Value>(null);
  const [meetingsLoading, setMeetingsLoading] = useState(true);
  const [meetings, setMeetings] = useState<Meetings[]>();
  const [objectionCheckbox, setObjectionCheckbox] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addAnotherMeeting, setAddAnotherMeeting] = useState(false);
  const [competitions, setCompetitions] = useState<OptionValue[]>([]);
  const [refetchMeetings, setRefetchMeetings] = useState(false);

  const [proceedToClosing, setProceedToClosing] = useState(false);

  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const toggleDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  const submitMeeting = () => {
    setSubmitting(true);
    DealUtils.createMeeting(
      props.pipelineSourceId,
      selectedDate?.toDateString() ?? '',
      'owest'
    )
      .then(() => {
        props.setRefetchDeal(!props.refetchDeal);
        setRefetchMeetings(!refetchMeetings);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
        setAddAnotherMeeting(false);
      });
  };

  const addMeetingDiv = (
    <div className={styles['meeting-date']}>
      <p>{t('meetingDate')}</p>

      <div className={styles['modal-wrapper']}>
        <button onClick={toggleDialog} className={styles['btn-outline']}>
          <p>
            {selectedDate
              ? format(new Date(selectedDate), 'dd MMMM yyyy')
              : t('selectDate')}
          </p>
          <img
            style={{ height: 'auto', rotate: '-90deg' }}
            src={ChevronLeft}
            alt=""
            role="presentation"
          />
        </button>
        <dialog ref={dialogRef} className={styles['modal']}>
          <Calendar
            value={selectedDate}
            minDate={new Date()}
            onChange={(date) => {
              setSelectedDate(date as Value);
              toggleDialog();
            }}
            view={'month'}
            prevLabel={
              <img
                style={{ height: 'auto' }}
                src={LeftArrowIcon}
                alt=""
                role="presentation"
              />
            }
            nextLabel={
              <img
                style={{ height: 'auto' }}
                src={RightArrowIcon}
                alt=""
                role="presentation"
              />
            }
          />
        </dialog>
      </div>
      <div className={styles['add-btn']}>
        <NavyButton
          disabled={!selectedDate || submitting}
          onClick={() => submitMeeting()}
        >
          <img src={PlusIcon} alt="" role="presentation" />
          {t('addMeeting')}
        </NavyButton>
        {submitting && <Loader />}
      </div>
    </div>
  );

  useEffect(() => {
    if (objectionCheckbox) {
      DealUtils.getCompetitionList()
        .then((res) => {
          setCompetitions(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [objectionCheckbox]);

  useEffect(() => {
    DealUtils.listMeetings(props.pipelineSourceId)
      .then((res) => {
        setMeetings(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setMeetingsLoading(false));
  }, [props.pipelineSourceId, refetchMeetings]);

  const schema = yup.object({
    objection: yup.bool(),
    objectionNotes: yup.string().nullable(),
    objectionReason: objectionCheckbox
      ? yup.object().nullable().required()
      : yup.object().nullable(),
    competitor: yup
      .object()
      .nullable()
      .when('objectionReason', {
        is: (objectionReason: OptionValue) =>
          objectionReason?.value === ObjectionValues.COMPETITION,
        then: yup.object().nullable().required(t('pleaseEnterCompetition')),
        otherwise: yup.object().nullable(),
      }),
  });
  const submitHandler = (values: UpdatePipelineInputInterface) => {
    setProceedToClosing(true);
    const data: UpdatePipeline = {
      objection: objectionCheckbox,
      lead_source_id: dealId ?? '',
      destination_slug: props.destinationSlug,
      // TODO: add feedback from pipeline details when integrated
      feedback_id: 1,
      sales_comment: '',
      objection_id: Number(values.objectionReason?.value),
      objection_notes: values.objectionNotes,
      competitor_source_id: values.competitor?.value
        ? String(values.competitor?.value)
        : '',
    };
    DealUtils.updateDealPipeline(props.pipelineSourceId, data)
      .then(() => {
        toast.success('Pipeline updated successfully');
        props.setRefetchDeal(!props.refetchDeal);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      })
      .finally(() => setProceedToClosing(false));
  };

  return (
    <div className={styles['meetings']}>
      <p className={styles['title']}>{t('scheduledMeetings')}</p>
      <p className={styles['description']}>{t('addMeetingDescription')}</p>
      {meetingsLoading ? (
        <Loader />
      ) : (
        <>
          {!meetings && addMeetingDiv}
          {meetings && (
            <>
              <div className={styles['meetings-wrapper']}>
                {meetings.map((meeting) => (
                  <div
                    key={meeting.meeting_source_id}
                    className={styles['meeting']}
                  >
                    <p className={styles['label']}>{meeting.meeting_name}</p>
                    <div className={styles['info']}>
                      <p>
                        {t('meetingDate')}
                        <span>{meeting.meeting_date}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles['btn-wrapper']}>
                <button
                  onClick={() => setAddAnotherMeeting(true)}
                  className={styles['add-btn']}
                  type="button"
                >
                  {t('addAnotherMeeting')}
                </button>
              </div>
              {addAnotherMeeting && addMeetingDiv}
              {props.dealStatus === DealStatusEnum.SALES_PITCH && (
                <div className={styles['objection-wrapper']}>
                  <p className={styles['title']}>{t('objections')}</p>

                  <Formik
                    initialValues={{
                      objection: false,
                      objectionReason: null as OptionValue | null,
                      competitor: null as OptionValue | null,
                      objectionNotes: '',
                    }}
                    validationSchema={schema}
                    onSubmit={submitHandler}
                  >
                    {({ errors, setFieldValue, values, touched, isValid }) => {
                      return (
                        <Form className={styles['form']}>
                          <div className={styles['checkbox-wrapper']}>
                            <input
                              name="objection"
                              type="checkbox"
                              checked={objectionCheckbox}
                              onChange={() =>
                                setObjectionCheckbox(!objectionCheckbox)
                              }
                            />
                            <p>{t('thereIsObjection')}</p>
                          </div>
                          {objectionCheckbox && (
                            <>
                              <div className="form__input">
                                <label>{t('selectObjectionReason')}</label>
                                <SelectDropdown
                                  name="destination"
                                  className={`${styles['select']} ${
                                    errors.objectionReason &&
                                    touched.objectionReason
                                      ? 'error'
                                      : ''
                                  }`}
                                  options={ObjectionOptions}
                                  placeholder={t('selectPlaceholder')}
                                  onChange={(val) => {
                                    setFieldValue('objectionReason', val);
                                  }}
                                  selectedOption={
                                    values.objectionReason || null
                                  }
                                  isClearable
                                />

                                <ErrorMessage
                                  name="objectionReason"
                                  component="div"
                                  className="form__error"
                                />
                              </div>
                              <div className="form__input">
                                <label htmlFor="objectionNotes">
                                  {t('objectionNotes')}
                                </label>
                                <Field
                                  id="objectionNotes"
                                  name="objectionNotes"
                                  type="text"
                                  className={
                                    errors.objectionNotes &&
                                    touched.objectionNotes
                                      ? 'error'
                                      : ''
                                  }
                                  placeholder={t('objectionNotes')}
                                />

                                <ErrorMessage
                                  name="objectionNotes"
                                  component="div"
                                  className="form__error"
                                />
                              </div>
                            </>
                          )}

                          {values.objectionReason?.value ===
                            ObjectionValues.COMPETITION && (
                            <div className="form__input">
                              <label htmlFor="competitor">
                                {t('competitor')}
                              </label>
                              <SelectDropdown
                                name="competitor"
                                className={`${styles['select']} ${
                                  errors.competitor && touched.competitor
                                    ? 'error'
                                    : ''
                                }`}
                                placeholder={t('selectPlaceholder')}
                                onChange={(option) =>
                                  setFieldValue('competitor', option)
                                }
                                options={competitions}
                                selectedOption={values.competitor || null}
                                isClearable
                              />
                              <ErrorMessage
                                name="competitor"
                                component="div"
                                className="form__error"
                              />
                            </div>
                          )}
                          <div className={styles['submit-btn']}>
                            <button
                              className="btn btn--navy"
                              type="submit"
                              disabled={!isValid || proceedToClosing}
                            >
                              {t('proceedToClosing')}
                            </button>
                            {proceedToClosing && <Loader />}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ScheduledMeetings;
