import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import { Customers as CustomerInterface } from '../definitions/interfaces/customers.interface';

export class Customer {
  public static getCustomers(
    viewNumber: number
  ): Promise<Payload<Payload<CustomerInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getCustomers.url(
        viewNumber
      )}`,
      {
        method: ENDPOINTS.getCustomers.method,
      }
    );
  }
}
