import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Loader, SelectDropdown } from '@orascom/common-components';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import newDealImg from '../../assets/images/new-deal.webp';
import styles from 'libs/broker-sales-man-common-components/src/styles/new-deal.module.scss';
import 'react-phone-input-2/lib/style.css';
import {
  ContactTimeEnum,
  ContactTypeEnum,
  MultiplePhoneNumbersFields,
} from '@orascom/broker-sales-man-common-components';
import {
  leadChannelOptions,
  Deal as DealUtils,
  feedbackOptions,
  customerInquiryOptions,
  LeadChannelValues,
} from '../../utils/deal.utils';
import { OptionValue } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  NewDealFormInterface,
  NewDealInputInterface,
} from '../../definitions/interfaces/deals.interface';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import DropdownIcon from '../../assets/icons/dropdown.svg';

export function NewDeal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitBtns, setSubmitBtns] = useState(false);

  const phoneSchema = yup.object({
    countryCode: yup.string().required(),
    phone: yup.string().required(t('pleaseEnterPhoneNumber')),
  });

  const [destinations, setDestinations] = useState<OptionValue[]>([]);
  const [ambassadorList, setAmbassadorList] = useState<OptionValue[]>();
  const [eventsList, setEventsList] = useState<OptionValue[]>();
  const [exhibitionList, setExhibitionList] = useState<OptionValue[]>();
  const [walkInList, setWalkInList] = useState<OptionValue[]>();
  const [brokerList, setBrokerList] = useState<OptionValue[]>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirectPage, setRedirectPage] = useState<'leads' | 'lead-details'>();

  useEffect(() => {
    Promise.all([
      DealUtils.getAmbassadorsList(),
      DealUtils.getEventsList(),
      DealUtils.getExhibitionsList(),
      DealUtils.getWalkInList(),
      DealUtils.getBrokerList(),
      DestinationUtils.listSalesManDestinations(),
    ])
      .then((res) => {
        setAmbassadorList(res[0]);
        setEventsList(res[1]);
        setExhibitionList(res[2]);
        setWalkInList(res[3]);
        setBrokerList(res[4]);
        setDestinations(
          res[5].map((destination) => ({
            label: destination.name,
            value: destination.slug,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const schema = yup.object({
    firstName: yup.string().required(t('pleaseEnterFirstName')),
    lastName: yup.string().required(t('pleaseEnterLastName')),
    phoneNumbers: yup.array().of(phoneSchema).max(3, 'maxThreePhoneNumbers'),
    destination: yup.object().nullable().required(t('required')),
    leadChannel: yup.object().nullable().required(t('required')),
    feedback: yup.object().nullable().required(t('required')),
    customerInquiry: yup.object().nullable().required(t('required')),
    ambassadorName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.AMBASSADOR,
        then: yup.object().nullable().required(t('pleaseEnterAmbassadorName')),
        otherwise: yup.object().nullable(),
      }),
    description: yup.string(),
    walkInName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.WALK_IN,
        then: yup.object().required(t('pleaseEnterWalkInName')),
        otherwise: yup.object().nullable(),
      }),
    eventsName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.EVENTS,
        then: yup.object().required(t('pleaseEnterEventsName')),
        otherwise: yup.object().nullable(),
      }),
    brokerName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.BROKER,
        then: yup.object().required(t('pleaseEnterBrokerName')),
        otherwise: yup.object().nullable(),
      }),
    exhibitionName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.EXHIBITIONS,
        then: yup.object().required(t('pleaseEnterExhibitionName')),
        otherwise: yup.object().nullable(),
      }),
    referralUnitDestination: yup.string().nullable(),
    referralCustomerName: yup.string().nullable(),
    unitNumber: yup
      .string()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.REFERRALS,
        then: yup.string().required(t('pleaseEnterUnitNumber')),
        otherwise: yup.string().nullable(),
      }),
    customer: yup
      .string()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.REFERRALS,
        then: yup.string().required(t('pleaseEnterCustomerName')),
        otherwise: yup.string().nullable(),
      }),
  });

  const submitHandler = (values: NewDealInputInterface) => {
    const mappedData: NewDealFormInterface = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone:
        '+' + values.phoneNumbers[0].countryCode + values.phoneNumbers[0].phone,
      destination_slug: String(values.destination?.value),
      customer_inquiry: String(values.customerInquiry?.value),
      preferred_communication_method: values.contactType?.value
        ? String(values.contactType?.value)
        : '',
      preferred_communication_time: values.contactTime?.value
        ? String(values.contactTime?.value)
        : '',
      channel_id: values.leadChannel?.value
        ? String(values.leadChannel?.value)
        : '',
      feedback_id: values.feedback?.value
        ? Number(values.feedback?.value)
        : undefined,
      event_source_id: values.eventsName?.value
        ? String(values.eventsName?.value)
        : '',
      ambassador_source_id: values.ambassadorName?.value
        ? String(values.ambassadorName?.value)
        : '',
      broker_source_id: values.brokerName?.value
        ? String(values.brokerName?.value)
        : '',
      office_source_id: values.walkInName?.value
        ? String(values.walkInName?.value)
        : '',
      exhibition_source_id: values.exhibitionName?.value
        ? String(values.exhibitionName?.value)
        : '',
      portal_comments: values.additionalInfo ?? '',
      customer: values.customer ?? '',
      referral_customer_name: values.referralCustomerName ?? '',
      referral_unit_destination: values.referralUnitDestination ?? '',
      unit_number: values.unitNumber ?? '',
      description: values.description ?? '',
    };

    if (values.phoneNumbers[1]?.countryCode && values.phoneNumbers[1]?.phone) {
      mappedData.phone_2 = `+${values.phoneNumbers[1].countryCode}${values.phoneNumbers[1].phone}`;
    }
    if (values.phoneNumbers[2]?.countryCode && values.phoneNumbers[2]?.phone) {
      mappedData.phone_3 = `+${values.phoneNumbers[2].countryCode}${values.phoneNumbers[2].phone}`;
    }

    setIsSubmitting(true);

    DealUtils.createLead(mappedData)
      .then((res) => {
        toast.success('Deal Created Successfully');
        if (redirectPage === 'leads') {
          navigate(ROUTES['MyDeals'].path);
        } else {
          navigate(
            ROUTES['DealDetails'].getPath?.(res.data.data.lead_source_id)
          );
        }
      })
      .catch((err) => {
        toast.error('failure to add lead');
        console.log(err);
      })
      .finally(() => setIsSubmitting(false));
  };

  if (isSubmitting) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('newLead')}</h1>
      <div className={styles['grid']}>
        <div className={styles['form__wrapper']}>
          <h3>{t('leadInformation')}</h3>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              unitName: '',
              unitType: '',
              clientBudget: '',
              description: '',
              contactType: null,
              contactTime: null,
              destination: null,
              additionalInfo: '',
              phoneNumbers: [{ countryCode: '20', phone: '' }],
              leadChannel: null as OptionValue | null,
              feedback: null as OptionValue | null,
              ambassadorName: null as OptionValue | null,
              walkInName: null as OptionValue | null,
              eventsName: null as OptionValue | null,
              brokerName: null as OptionValue | null,
              exhibitionName: null as OptionValue | null,
              unitNumber: '',
              referralCustomerName: '',
              customer: '',
              referralUnitDestination: '',
              customerInquiry: null as OptionValue | null,
            }}
            validationSchema={schema}
            onSubmit={submitHandler}
          >
            {({ errors, setFieldValue, values, touched, isValid }) => {
              return (
                <Form className={styles['form']}>
                  <div className="form__input">
                    <label htmlFor="firstName">{t('firstName')}</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      className={
                        errors.firstName && touched.firstName ? 'error' : ''
                      }
                      placeholder={t('prospectiveBuyerFirstName')}
                    />

                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="form__error"
                    />
                  </div>
                  <div className="form__input">
                    <label htmlFor="lastName">{t('lastName')}</label>
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      className={
                        errors.lastName && touched.lastName ? 'error' : ''
                      }
                      placeholder={t('prospectiveBuyerLastName')}
                    />

                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <MultiplePhoneNumbersFields
                    phoneNumbers={values.phoneNumbers}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />

                  <div className="form__input">
                    <label>{t('destination')}</label>
                    <SelectDropdown
                      name="destination"
                      className={`${styles['select']} ${
                        errors.destination && touched.destination ? 'error' : ''
                      }`}
                      options={destinations}
                      placeholder={t('selectPlaceholder')}
                      onChange={(val) => {
                        setFieldValue('destination', val);
                      }}
                      selectedOption={values.destination || null}
                      isClearable
                    />

                    <ErrorMessage
                      name="destination"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <div className="form__input">
                    <label htmlFor="leadChannel">{t('leadChannel')}</label>
                    <SelectDropdown
                      name="leadChannel"
                      className={`${styles['select']} ${
                        errors.leadChannel && touched.leadChannel ? 'error' : ''
                      }`}
                      placeholder={t('selectPlaceholder')}
                      onChange={(option) =>
                        setFieldValue('leadChannel', option)
                      }
                      options={leadChannelOptions}
                      selectedOption={values.leadChannel || null}
                      isClearable
                    />

                    <ErrorMessage
                      name="leadChannel"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  {values.leadChannel?.value ===
                    LeadChannelValues.AMBASSADOR && (
                    <>
                      <div className="form__input">
                        <label htmlFor="ambassadorName">
                          {t('ambassadorName')}
                        </label>
                        <SelectDropdown
                          name="ambassadorName"
                          className={`${styles['select']} ${
                            errors.ambassadorName && touched.ambassadorName
                              ? 'error'
                              : ''
                          }`}
                          placeholder={t('selectPlaceholder')}
                          onChange={(option) =>
                            setFieldValue('ambassadorName', option)
                          }
                          options={ambassadorList}
                          selectedOption={values.ambassadorName || null}
                          isClearable
                        />
                        <ErrorMessage
                          name="ambassadorName"
                          component="div"
                          className="form__error"
                        />
                      </div>
                      <div className="form__input">
                        <label htmlFor="description">
                          {t('description')} ({t('optional')})
                        </label>
                        <Field
                          id="description"
                          name="description"
                          as="textarea"
                          className={styles['textarea']}
                          placeholder={t('description')}
                        />

                        <ErrorMessage
                          name="description"
                          component="div"
                          className="form__error"
                        />
                      </div>
                    </>
                  )}

                  {values.leadChannel?.value === LeadChannelValues.BROKER && (
                    <div className="form__input">
                      <label htmlFor="brokerName">{t('brokerName')}</label>
                      <SelectDropdown
                        name="brokerName"
                        className={`${styles['select']} ${
                          errors.brokerName && touched.brokerName ? 'error' : ''
                        }`}
                        placeholder={t('selectPlaceholder')}
                        onChange={(option) =>
                          setFieldValue('brokerName', option)
                        }
                        options={brokerList}
                        selectedOption={values.brokerName || null}
                        isClearable
                      />

                      <ErrorMessage
                        name="brokerName"
                        component="div"
                        className="form__error"
                      />
                    </div>
                  )}

                  {values.leadChannel?.value === LeadChannelValues.WALK_IN && (
                    <div className="form__input">
                      <label htmlFor="walkInName">{t('officeName')}</label>
                      <SelectDropdown
                        name="walkInName"
                        className={`${styles['select']} ${
                          errors.walkInName && touched.walkInName ? 'error' : ''
                        }`}
                        placeholder={t('selectPlaceholder')}
                        onChange={(option) =>
                          setFieldValue('walkInName', option)
                        }
                        options={walkInList}
                        selectedOption={values.walkInName || null}
                        isClearable
                      />

                      <ErrorMessage
                        name="walkInName"
                        component="div"
                        className="form__error"
                      />
                    </div>
                  )}

                  {values.leadChannel?.value === LeadChannelValues.EVENTS && (
                    <div className="form__input">
                      <label htmlFor="eventsName">{t('eventsName')}</label>
                      <SelectDropdown
                        name="eventsName"
                        className={`${styles['select']} ${
                          errors.eventsName && touched.eventsName ? 'error' : ''
                        }`}
                        placeholder={t('selectPlaceholder')}
                        onChange={(option) =>
                          setFieldValue('eventsName', option)
                        }
                        options={eventsList}
                        selectedOption={values.eventsName || null}
                        isClearable
                      />

                      <ErrorMessage
                        name="eventsName"
                        component="div"
                        className="form__error"
                      />
                    </div>
                  )}

                  {values.leadChannel?.value ===
                    LeadChannelValues.REFERRALS && (
                    <>
                      <div className="form__input">
                        <label htmlFor="referralCustomerName">
                          {t('referralCustomerName')} ({t('optional')})
                        </label>
                        <Field
                          id="referralCustomerName"
                          name="referralCustomerName"
                          as="textarea"
                          className={styles['textarea']}
                          placeholder={t('referralCustomerName')}
                        />

                        <ErrorMessage
                          name="referralCustomerName"
                          component="div"
                          className="form__error"
                        />
                      </div>
                      <div className="form__input">
                        <label htmlFor="customer">{t('customer')}</label>
                        <Field
                          id="customer"
                          name="customer"
                          as="textarea"
                          className={styles['textarea']}
                          placeholder={t('customer')}
                        />

                        <ErrorMessage
                          name="customer"
                          component="div"
                          className="form__error"
                        />
                      </div>
                      <div className="form__input">
                        <label htmlFor="referralUnitDestination">
                          {t('referralUnitDestination')}({t('optional')})
                        </label>
                        <Field
                          id="referralUnitDestination"
                          name="referralUnitDestination"
                          as="textarea"
                          className={styles['textarea']}
                          placeholder={t('referralUnitDestination')}
                        />

                        <ErrorMessage
                          name="referralUnitDestination"
                          component="div"
                          className="form__error"
                        />
                      </div>
                      <div className="form__input">
                        <label htmlFor="unitNumber">{t('unitNumber')}</label>
                        <Field
                          id="unitNumber"
                          name="unitNumber"
                          as="textarea"
                          className={styles['textarea']}
                          placeholder={t('unitNumber')}
                        />

                        <ErrorMessage
                          name="unitNumber"
                          component="div"
                          className="form__error"
                        />
                      </div>
                    </>
                  )}

                  {values.leadChannel?.value ===
                    LeadChannelValues.EXHIBITIONS && (
                    <div className="form__input">
                      <label htmlFor="exhibitionName">
                        {t('exhibitionName')}
                      </label>
                      <SelectDropdown
                        name="exhibitionName"
                        className={`${styles['select']} ${
                          errors.exhibitionName && touched.exhibitionName
                            ? 'error'
                            : ''
                        }`}
                        placeholder={t('selectPlaceholder')}
                        onChange={(option) =>
                          setFieldValue('exhibitionName', option)
                        }
                        options={exhibitionList}
                        selectedOption={values.exhibitionName || null}
                        isClearable
                      />
                      <ErrorMessage
                        name="exhibitionName"
                        component="div"
                        className="form__error"
                      />
                    </div>
                  )}

                  <div className="form__input">
                    <label htmlFor="customerInquiry">
                      {t('customerInquiry')}
                    </label>
                    <SelectDropdown
                      name="customerInquiry"
                      className={`${styles['select']} ${
                        errors.customerInquiry && touched.customerInquiry
                          ? 'error'
                          : ''
                      }`}
                      placeholder={t('selectPlaceholder')}
                      onChange={(option) =>
                        setFieldValue('customerInquiry', option)
                      }
                      options={customerInquiryOptions}
                      selectedOption={values.customerInquiry || null}
                      isClearable
                    />

                    <ErrorMessage
                      name="customerInquiry"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <div className="form__input">
                    <label htmlFor="feedback">{t('feedback')}</label>
                    <SelectDropdown
                      name="feedback"
                      className={`${styles['select']} ${
                        errors.feedback && touched.feedback ? 'error' : ''
                      }`}
                      placeholder={t('selectPlaceholder')}
                      onChange={(option) => setFieldValue('feedback', option)}
                      options={feedbackOptions}
                      selectedOption={values.feedback || null}
                      isClearable
                    />

                    <ErrorMessage
                      name="feedback"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <div className="form__input">
                    <label htmlFor="contactType">
                      {t('preferredWayOfContact')} ({t('optional')})
                    </label>
                    <SelectDropdown
                      name="contactType"
                      className={`${styles['select']} ${
                        errors.contactType && touched.contactType ? 'error' : ''
                      }`}
                      placeholder={t('selectPlaceholder')}
                      onChange={(option) =>
                        setFieldValue('contactType', option)
                      }
                      options={[
                        {
                          value: ContactTypeEnum.VIRTUAL_MEETING,
                          label: 'Virtual Meeting',
                        },
                        { value: ContactTypeEnum.MOBILE, label: 'Mobile' },
                        { value: ContactTypeEnum.EMAIL, label: 'Email' },
                        { value: ContactTypeEnum.WHATS_APP, label: 'WhatsApp' },
                      ]}
                      selectedOption={values.contactType || null}
                    />

                    <ErrorMessage
                      name="contactType"
                      component="div"
                      className="form__error"
                    />
                  </div>
                  <div className="form__input">
                    <label htmlFor="contactTime">
                      {t('preferredTimeOfContact')} ({t('optional')})
                    </label>
                    <SelectDropdown
                      name="contactTime"
                      className={`${styles['select']} ${
                        errors.contactTime && touched.contactTime ? 'error' : ''
                      }`}
                      placeholder={t('selectPlaceholder')}
                      onChange={(option) =>
                        setFieldValue('contactTime', option)
                      }
                      options={[
                        { value: ContactTimeEnum.MORNING, label: 'Morning' },
                        {
                          value: ContactTimeEnum.AFTERNOON,
                          label: 'Afternoon',
                        },
                        { value: ContactTimeEnum.NIGHT, label: 'Night' },
                      ]}
                      selectedOption={values.contactTime || null}
                    />

                    <ErrorMessage
                      name="contactTime"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <div className="form__input">
                    <label htmlFor="additionalInfo">
                      {t('additionalInfo')} ({t('optional')})
                    </label>
                    <Field
                      id="additionalInfo"
                      name="additionalInfo"
                      as="textarea"
                      className={styles['textarea']}
                      placeholder={t('additionalNotes')}
                    />

                    <ErrorMessage
                      name="additionalInfo"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  <div className={styles['button-wrapper']}>
                    <div className={styles['create-wrapper']}>
                      <button
                        className={`${styles['create-btn']} btn btn--navy`}
                        disabled={!isValid}
                        type="button"
                        onClick={() => {
                          if (isValid) {
                            setSubmitBtns(true);
                          }
                        }}
                      >
                        {t('createLead')}
                        <hr />
                        <img src={DropdownIcon} alt="" role="presentation" />
                      </button>
                      <div
                        style={{ display: submitBtns ? 'flex' : 'none' }}
                        className={styles['submit-btns']}
                      >
                        <button
                          onClick={() => setRedirectPage('lead-details')}
                          type="submit"
                          disabled={!isValid}
                        >
                          {t('createLeadAndLeadPage')}
                        </button>
                        <hr />
                        <button
                          onClick={() => setRedirectPage('leads')}
                          type="submit"
                          disabled={!isValid}
                        >
                          {t('createLeadandLeadsPage')}
                        </button>
                      </div>
                    </div>

                    <Link to={ROUTES['MyDeals'].path} className="anchor">
                      {t('cancel')}
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <img
          src={newDealImg}
          alt="new-deal"
          className={styles['image']}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default NewDeal;
