import { SelectDropdown } from '@orascom/common-components';
import styles from './pipeline-type.module.scss';
import { useTranslation } from 'react-i18next';
import { FormikPassedStateProps } from '../../definitions/interfaces/lead-stages.interface';
import { ErrorMessage } from 'formik';

export function PipelineType({ setFieldValue }: FormikPassedStateProps) {
  const { t } = useTranslation();
  return (
    <div className={styles['pipeline-type']}>
      <p className={styles['title']}>{t('pipelineType')}</p>

      <div className={styles['pipeline-input']}>
        <label htmlFor="pipeline_type">{t('type')}</label>
        <SelectDropdown
          name="pipeline_type"
          options={[
            { label: t('singleUnit'), value: 1 },
            { label: t('multipleUnits'), value: 2 },
          ]}
          placeholder={t('selectPlaceholder')}
          className={styles['select']}
          onChange={(value) => {
            setFieldValue('pipeline_type', value?.value);
          }}
        />
        <ErrorMessage
          name="pipeline_type"
          component="div"
          className={styles['error']}
        />
      </div>
    </div>
  );
}

export default PipelineType;
