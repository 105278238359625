import styles from './deal-details.module.scss';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { Link, useParams } from 'react-router-dom';
import DealDetailsBreadcrumbs from '../../components/deal-details-breadcrumbs/deal-details-breadcrumbs';
import DealInfoCard from '../../components/deal-info-card/deal-info-card';
import { DealStatusEnum } from '../../definitions/interfaces/deals.interface';
import { useEffect, useState } from 'react';
import { Deal as DealUtils } from '../../utils/deal.utils';
import {
  LeadClosing,
  LeadDicovery,
  SalesPitch,
} from '../../components/lead-stages/lead-stages';
import {
  LeadDetails,
  PipelineDetails,
} from '../../definitions/interfaces/lead-stages.interface';
import { Loader } from '@orascom/common-components';

export function DealDetails() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [dealStatus, setDealStatus] = useState<DealStatusEnum>(
    DealStatusEnum.LEAD_DISCOVERY
  );
  const { dealId } = useParams();
  const [refetchDeal, setRefetchDeal] = useState(true);
  const [leadDetails, setLeadDetails] = useState<LeadDetails>();
  const [pipeline, setPipeline] = useState<PipelineDetails>();

  useEffect(() => {
    if (dealId) {
      Promise.all([
        DealUtils.getLeadDetails(dealId),
        DealUtils.getPipelineDetails(dealId),
      ])
        .then((res) => {
          setLeadDetails(res[0]);
          setPipeline(res[1]);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [refetchDeal, dealId]);

  useEffect(() => {
    if (!pipeline) {
      setDealStatus(DealStatusEnum.LEAD_DISCOVERY);
    } else if (pipeline.current_phase === 'Discovery') {
      setDealStatus(DealStatusEnum.SALES_PITCH);
    } else {
      setDealStatus(DealStatusEnum.DEAL_CLOSING);
    }
  }, [pipeline]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('leadDetails')}</h1>
      <div className="card">
        <DealDetailsBreadcrumbs dealStatus={dealStatus} />
        <div className={styles['deal-content']}>
          {dealId && dealStatus === DealStatusEnum.DEAL_CLOSING && (
            <LeadClosing
              dealId={dealId}
              pipelineSourceId="51c8f1e8-f2b6-ef11-b8e8-000d3a2d05aa"
              destinationSlug="owest"
              refetchDeal={refetchDeal}
              setRefetchDeal={setRefetchDeal}
            />
          )}
          {dealId && dealStatus === DealStatusEnum.LEAD_DISCOVERY && (
            <LeadDicovery
              dealId={dealId}
              setRefetchDeal={setRefetchDeal}
              refetchDeal={refetchDeal}
              pipelineDetails={pipeline}
            />
          )}
          {dealId && dealStatus === DealStatusEnum.SALES_PITCH && (
            <SalesPitch
              dealId={dealId}
              pipelineSourceId="51c8f1e8-f2b6-ef11-b8e8-000d3a2d05aa"
              destinationSlug="makadi-heights"
              refetchDeal={refetchDeal}
              setRefetchDeal={setRefetchDeal}
              pipelineDetails={pipeline}
            />
          )}
          {leadDetails && <DealInfoCard leadDetails={leadDetails} />}
        </div>
      </div>
    </div>
  );
}

export default DealDetails;
