import { useParams } from 'react-router-dom';
import { FeedbackSubmission } from '../../definitions/interfaces/lead-stages.interface';
import styles from './submit-feedback.module.scss';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { useState } from 'react';
import LeadFeedback from '../lead-feedback/lead-feedback';
import { Loader, NavyButton } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
export interface SubmitFeedbackProps {
  pipelineSourceId: string;
  destinationSlug: string;
  setRefetchActivityLog?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchActivityLog?: boolean;
  setRefetchDeal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDeal: boolean;
}
export function SubmitFeedback(props: Readonly<SubmitFeedbackProps>) {
  const { dealId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();

  const initialValues = {
    feedback_id: 0,
    sales_comment: '',
    lead_source_id: dealId ?? '',
    destination_slug: props.destinationSlug,
  };
  const schema = yup.object({
    feedback_id: yup
      .number()
      .required(t('required'))
      .moreThan(0, t('pleaseChooseAnOption')),
    sales_comment: yup.string(),
  });

  const submitHandler = (values: FeedbackSubmission) => {
    setSubmitting(true);
    const mappedData = {
      feedback_id: Number(values.feedback_id),
      sales_comment: values.sales_comment,
      lead_source_id: dealId ?? '',
      destination_slug: props.destinationSlug,
    };
    DealUtils.updateDealPipeline(props.pipelineSourceId, mappedData)
      .then(() => {
        props.setRefetchDeal(!props.refetchDeal);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={styles['editable-feedback']}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {({ setFieldValue, isValid }) => {
          return (
            <Form>
              <LeadFeedback setFieldValue={setFieldValue} />
              <div className={styles['submit']}>
                <NavyButton disabled={!isValid || submitting} type="submit">
                  {t('addFeedback')}
                </NavyButton>
                {submitting && <Loader />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SubmitFeedback;
