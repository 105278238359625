import styles from './initial-meeting.module.scss';
import { useTranslation } from 'react-i18next';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import Calendar from 'react-calendar';
import { useRef, useState } from 'react';
import { format } from 'date-fns';
import { FormikPassedStateProps } from '../../definitions/interfaces/lead-stages.interface';
import { DealStatusEnum } from '../../definitions/interfaces/deals.interface';

export type Value = Date | null;

export interface InitialMeetingProps {
  formikProps?: FormikPassedStateProps;
  dealStatus: DealStatusEnum;
  meetingDate?: string;
}
export function InitialMeeting(props: InitialMeetingProps) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Value>();

  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const toggleDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  if (props.dealStatus === DealStatusEnum.LEAD_DISCOVERY && props.formikProps) {
    return (
      <div className={styles['initial-meeting-input']}>
        <p className={styles['title']}>{t('initialMeeting')}</p>
        <div className={styles['meeting-date']}>
          <p>{t('meetingDate')}</p>

          <div className={styles['modal-wrapper']}>
            <button onClick={toggleDialog} className={styles['btn-outline']}>
              <p>
                {selectedDate
                  ? format(new Date(selectedDate), 'yyyy-MM-dd')
                  : t('selectDate')}
              </p>
              <img
                style={{ height: 'auto', rotate: '-90deg' }}
                src={ChevronLeft}
                alt=""
                role="presentation"
              />
            </button>
            <dialog ref={dialogRef} className={styles['modal']}>
              <Calendar
                value={selectedDate}
                maxDate={new Date()}
                onChange={(date) => {
                  setSelectedDate(date as Value);
                  props.formikProps?.setFieldValue?.(
                    'sales_first_contact_date',
                    format(date as Date, 'yyyy-MM-dd')
                  );
                  toggleDialog();
                }}
                view={'month'}
                prevLabel={
                  <img
                    style={{ height: 'auto' }}
                    src={LeftArrowIcon}
                    alt=""
                    role="presentation"
                  />
                }
                nextLabel={
                  <img
                    style={{ height: 'auto' }}
                    src={RightArrowIcon}
                    alt=""
                    role="presentation"
                  />
                }
              />
            </dialog>
            {props.formikProps?.touched?.sales_first_contact_date &&
              props.formikProps?.errors?.sales_first_contact_date && (
                <div className={styles['error']}>
                  {props.formikProps?.errors?.sales_first_contact_date}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles['initial-meeting']}>
        <p className={styles['title']}>{t('initialMeeting')}</p>
        <div className={styles['meeting-date']}>
          <p>{t('meetingDate')}:</p>
          {props.meetingDate && (
            <p>{format(new Date(props.meetingDate), 'dd MMMM yyyy')}</p>
          )}
        </div>
      </div>
    );
  }
}
export default InitialMeeting;
