import {
  DealInterface,
  Ambassador,
  Exhibition,
  WalkIn,
  Event as EventType,
  NewDealFormInterface,
  Broker,
  Meetings,
  Feedback,
  DesiredUnit,
  DealCreationResponse,
  UpdatePipeline,
  Competition,
} from '../definitions/interfaces/deals.interface';
import { Payload, OptionValue } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import { Deal as DealAPI } from '../api/deal';
import {
  FeedbackSubmission,
  LeadDetails,
  LeadDiscoveryInterface,
  PipelineDetails,
} from '../definitions/interfaces/lead-stages.interface';

export class Deal {
  public static createLead(
    leadData: NewDealFormInterface
  ): Promise<Payload<Payload<DealCreationResponse>>> {
    return DealAPI.createLead(leadData)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static createLeadDiscoveryPipeline(
    leadData: LeadDiscoveryInterface
  ): Promise<Payload<void>> {
    return DealAPI.createLeadDiscoveryPipeline(leadData)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getLeads(viewNumber: number): Promise<DealInterface[]> {
    return DealAPI.getLeads(viewNumber)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getLeadDetails(id: string): Promise<LeadDetails> {
    return DealAPI.getLeadDetails(id)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getPipelineDetails(leadId: string): Promise<PipelineDetails> {
    return DealAPI.getPipelineDetails(leadId)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getBrokerList(): Promise<OptionValue[]> {
    return DealAPI.getBrokerList()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (broker: Broker) => ({
            label: broker.broker_name,
            value: broker.broker_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getAmbassadorsList(): Promise<OptionValue[]> {
    return DealAPI.getAmbassadorsList()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (ambassador: Ambassador) => ({
            label: ambassador.ambassador_name,
            value: ambassador.ambassador_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getEventsList(): Promise<OptionValue[]> {
    return DealAPI.getEventsList()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (event: EventType) => ({
            label: event.event_name,
            value: event.event_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static listDesiredUnit(): Promise<OptionValue[]> {
    return DealAPI.listDesiredUnit()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (unit: DesiredUnit) => ({
            label: unit.unit_name,
            value: unit.unit_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getExhibitionsList(): Promise<OptionValue[]> {
    return DealAPI.getExhibitionsList()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (exhibition: Exhibition) => ({
            label: exhibition.exhibition_name,
            value: exhibition.exhibition_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getWalkInList(): Promise<OptionValue[]> {
    return DealAPI.getWalkInList()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (walkIn: WalkIn) => ({
            label: walkIn.office_name,
            value: walkIn.office_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getCompetitionList(): Promise<OptionValue[]> {
    return DealAPI.getCompetitionList()
      .then((result) => {
        const mappedResult: OptionValue[] = result.data.data.map(
          (competition: Competition) => ({
            label: competition.competitor_name,
            value: competition.competitor_source_id,
          })
        );
        return Promise.resolve(mappedResult);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static listFeedback(pipelineSourceId: string): Promise<Feedback[]> {
    return DealAPI.listFeedback(pipelineSourceId)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static listMeetings(pipelineSourceId: string): Promise<Meetings[]> {
    return DealAPI.listMeetings(pipelineSourceId)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static createMeeting(
    pipelineSourceId: string,
    meetingDate: string,
    destinationSlug: string
  ): Promise<Meetings[]> {
    return DealAPI.createMeeting(pipelineSourceId, meetingDate, destinationSlug)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static updateDealPipeline(
    pipelineSourceId: string,
    leadData: UpdatePipeline | FeedbackSubmission
  ): Promise<void> {
    return DealAPI.updateDealPipeline(pipelineSourceId, leadData)
      .then((result) => {
        return Promise.resolve(result.data.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}
export enum LeadChannelValues {
  AMBASSADOR = 27,
  BROKER = 26,
  DIRECT_MANAGER = 14,
  DIRECTOR = 34,
  EMPLOYEE_STAFF = 17,
  EVENTS = 31,
  EXHIBITIONS = 30,
  HEAD_OFFICE = 15,
  ONLINE = 5,
  PERSONAL_CONTACT = 13,
  REFERRALS = 25,
  WALK_IN = 16,
}
export const leadChannelOptions = [
  { value: LeadChannelValues.AMBASSADOR, label: 'Ambassador' },
  { value: LeadChannelValues.BROKER, label: 'Broker' },
  { value: LeadChannelValues.DIRECT_MANAGER, label: 'Direct Manager' },
  { value: LeadChannelValues.DIRECTOR, label: 'Director' },
  { value: LeadChannelValues.EMPLOYEE_STAFF, label: 'Employee Staff' },
  { value: LeadChannelValues.EVENTS, label: 'Events' },
  { value: LeadChannelValues.EXHIBITIONS, label: 'Exhibitions' },
  { value: LeadChannelValues.HEAD_OFFICE, label: 'Head Office' },
  { value: LeadChannelValues.ONLINE, label: 'Online' },
  { value: LeadChannelValues.PERSONAL_CONTACT, label: 'Personal Contact' },
  { value: LeadChannelValues.REFERRALS, label: 'Referrals' },
  { value: LeadChannelValues.WALK_IN, label: 'Walk In' },
];

export const leadChannel: { [key: number]: string } = {
  [LeadChannelValues.AMBASSADOR]: 'Ambassador',
  [LeadChannelValues.BROKER]: 'Broker',
  [LeadChannelValues.DIRECT_MANAGER]: 'Direct Manager',
  [LeadChannelValues.DIRECTOR]: 'Director',
  [LeadChannelValues.EMPLOYEE_STAFF]: 'Employee Staff',
  [LeadChannelValues.EVENTS]: 'Events',
  [LeadChannelValues.EXHIBITIONS]: 'Exhibitions',
  [LeadChannelValues.HEAD_OFFICE]: 'Head Office',
  [LeadChannelValues.ONLINE]: 'Online',
  [LeadChannelValues.PERSONAL_CONTACT]: 'Personal Contact',
  [LeadChannelValues.REFERRALS]: 'Referrals',
  [LeadChannelValues.WALK_IN]: 'Walk In',
};

export enum ObjectionValues {
  NONE = 1,
  UNIT_PRICE = 2,
  PAYMENT = 3,
  LOCATION = 4,
  COMPETITION = 5,
  DESIRED_TYPE_NOT_AVAILABLE = 6,
  DESIRED_UNIT_NOT_AVAILABLE = 7,
  OTHERS = 8,
}
export const ObjectionOptions = [
  { value: ObjectionValues.UNIT_PRICE, label: 'Unit Price' },
  { value: ObjectionValues.PAYMENT, label: 'Payment' },
  { value: ObjectionValues.LOCATION, label: 'Location' },
  { value: ObjectionValues.COMPETITION, label: 'Competition' },
  {
    value: ObjectionValues.DESIRED_TYPE_NOT_AVAILABLE,
    label: 'Desired type not available',
  },
  {
    value: ObjectionValues.DESIRED_UNIT_NOT_AVAILABLE,
    label: 'Desired unit not available',
  },
  { value: ObjectionValues.OTHERS, label: 'Others' },
];
export const customerInquiryOptions = [
  { label: 'investment inquiry', value: '1' },
  { label: 'unit purchasing inquiry', value: '2' },
  { label: 'general inquiry', value: '3' },
];

export const feedbackOptions = [
  {
    label: 'Follow Up',
    value: 8,
  },
  {
    label: 'Interested',
    value: 1,
  },
  {
    label: 'Not Interested',
    value: 2,
  },
  {
    label: 'No Answer',
    value: 3,
  },
  {
    label: 'Not Eligible',
    value: 5,
  },
  {
    label: 'Wrong Number',
    value: 7,
  },
  {
    label: 'Price Concern',
    value: 9,
  },
  {
    label: 'Product Concern',
    value: 10,
  },
  {
    label: 'Payment Term',
    value: 11,
  },
  {
    label: 'Competition',
    value: 12,
  },
  {
    label: 'Pending on Sales Advisor',
    value: 13,
  },
  {
    label: 'Not Responding',
    value: 14,
  },
  {
    label: 'Others',
    value: 15,
  },
];
