import { Loader, NavyButton, SelectDropdown } from '@orascom/common-components';
import styles from './deal-close.module.scss';
import { useTranslation } from 'react-i18next';
import { OptionValue } from '@orascom/api-interfaces';
import { useEffect, useRef, useState } from 'react';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { Value } from '../initial-meeting/initial-meeting';
import { format } from 'date-fns';
import Calendar from 'react-calendar';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import { ErrorMessage, Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  UpdatePipeline,
  UpdatePipelineInputInterface,
} from '../../definitions/interfaces/deals.interface';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export interface DealCloseProps {
  pipelineSourceId: string;
  destinationSlug: string;
  setRefetchDeal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDeal: boolean;
}
export function DealClose(props: Readonly<DealCloseProps>) {
  const { t } = useTranslation();
  const [desiredUnits, setDesiredUnits] = useState<OptionValue[]>();
  const [selectedDate, setSelectedDate] = useState<Value>(null);
  const { dealId } = useParams();

  const [loadingUnits, setLoadingUnits] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const toggleDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  useEffect(() => {
    DealUtils.listDesiredUnit()
      .then((res) => {
        setDesiredUnits(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingUnits(false));
  }, []);

  const schema = yup.object({
    desiredUnit: yup.object().nullable().required(t('required')),
    selectedDate: yup.string().nullable().required(t('required')),
  });

  const submitHandler = (values: UpdatePipelineInputInterface) => {
    setSubmitting(true);
    const data: UpdatePipeline = {
      lead_source_id: dealId ?? '',
      destination_slug: props.destinationSlug,
      objection: false,
      // TODO: add feedback from pipeline details when integrated
      feedback_id: 1,
      sales_comment: '',
      unit_source_id: String(values.desiredUnit?.value),
      closure_date: values.selectedDate,
    };
    DealUtils.updateDealPipeline(props.pipelineSourceId, data)
      .then(() => {
        toast.success('Pipeline updated successfully');
        props.setRefetchDeal(!props.refetchDeal);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className={styles['deal-close']}>
      <p className={styles['title']}>{t('closeDeal')}</p>
      <p className={styles['description']}>{t('closeDealDescription')}</p>

      <Formik
        initialValues={{
          desiredUnit: null as OptionValue | null,
          selectedDate: '',
        }}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {({ errors, setFieldValue, values, touched, isValid }) => {
          return (
            <Form className={styles['form']}>
              <div className={styles['select-section']}>
                <label htmlFor="desiredUnit">{t('chooseUnit')}</label>
                <SelectDropdown
                  isLoading={loadingUnits}
                  name="desiredUnit"
                  className={`${styles['select']} ${
                    errors.desiredUnit && touched.desiredUnit ? 'error' : ''
                  }`}
                  options={desiredUnits}
                  placeholder={t('selectPlaceholder')}
                  onChange={(val) => {
                    setFieldValue('desiredUnit', val);
                  }}
                  selectedOption={values.desiredUnit || null}
                  isClearable
                />

                <ErrorMessage
                  name="desiredUnit"
                  component="div"
                  className="form__error"
                />

                <div className={styles['meeting-date']}>
                  <label htmlFor="selectedDate">{t('selectDate')}</label>
                  <ErrorMessage
                    name="selectedDate"
                    component="div"
                    className="form__error"
                  />
                  <div className={styles['modal-wrapper']}>
                    <button
                      onClick={toggleDialog}
                      className={styles['btn-outline']}
                    >
                      <p>
                        {selectedDate
                          ? format(new Date(selectedDate), 'dd MMMM yyyy')
                          : t('selectDate')}
                      </p>
                      <img
                        style={{ height: 'auto', rotate: '-90deg' }}
                        src={ChevronLeft}
                        alt=""
                        role="presentation"
                      />
                    </button>
                    <dialog ref={dialogRef} className={styles['modal']}>
                      <Calendar
                        value={values.selectedDate}
                        minDate={new Date()}
                        onChange={(date) => {
                          setSelectedDate(date as Value);
                          setFieldValue('selectedDate', date);
                          toggleDialog();
                        }}
                        view={'month'}
                        prevLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={LeftArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                        nextLabel={
                          <img
                            style={{ height: 'auto' }}
                            src={RightArrowIcon}
                            alt=""
                            role="presentation"
                          />
                        }
                      />
                    </dialog>
                  </div>
                </div>
              </div>
              <div className={styles['submit-btn']}>
                <NavyButton disabled={!isValid || submitting} type="submit">
                  {t('scheduleClosing')}
                </NavyButton>
                {submitting && <Loader />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default DealClose;
