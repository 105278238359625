import styles from './deal-info-card.module.scss';
import { useTranslation } from 'react-i18next';
import CircularIcon from '../circular-icon/circular-icon';
import { LeadDetails } from '../../definitions/interfaces/lead-stages.interface';
import { leadChannel } from '../../utils/deal.utils';

export function DealInfoCard({ leadDetails }: { leadDetails: LeadDetails }) {
  const { t } = useTranslation();

  return (
    <div className={`${styles['wrapper']} card`}>
      <h1 className={styles['title']}>{t('leadInfo')}</h1>
      <div className={styles['details']}>
        <div className={styles['info']}>
          <p>{t('fullName')}</p>
          <p>
            {leadDetails.first_name} {leadDetails.last_name}
          </p>
        </div>
        <div className={styles['info']}>
          <p> {t('phoneNumber')}</p>
          <p> {leadDetails.phone}</p>
          <div className={styles['btns']}>
            <CircularIcon>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <g clipPath="url(#a)">
                    <path
                      stroke="#003A70"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.333 2.667H6L7.333 6 5.667 7A7.33 7.33 0 0 0 9 10.333l1-1.666L13.333 10v2.667A1.333 1.333 0 0 1 12 14 10.667 10.667 0 0 1 2 4a1.333 1.333 0 0 1 1.333-1.333"
                    />
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path fill="#fff" d="M0 0h16v16H0z" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </CircularIcon>
            <CircularIcon>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <path
                    fill="#003A70"
                    d="M12.7 3.273a6.55 6.55 0 0 0-4.673-1.94C4.387 1.333 1.42 4.3 1.42 7.94c0 1.167.307 2.3.88 3.3l-.933 3.427 3.5-.92a6.6 6.6 0 0 0 3.16.806c3.64 0 6.606-2.966 6.606-6.606A6.57 6.57 0 0 0 12.7 3.273m-4.673 10.16a5.5 5.5 0 0 1-2.8-.766l-.2-.12-2.08.546.553-2.026-.133-.207a5.5 5.5 0 0 1-.84-2.92A5.503 5.503 0 0 1 8.02 2.447c1.467 0 2.847.573 3.88 1.613a5.45 5.45 0 0 1 1.607 3.887c.013 3.026-2.454 5.486-5.48 5.486m3.013-4.106c-.167-.08-.98-.48-1.127-.54-.153-.054-.26-.08-.373.08-.113.166-.427.54-.52.646-.093.114-.193.127-.36.04-.167-.08-.7-.26-1.327-.82-.493-.44-.82-.98-.92-1.146-.093-.167-.013-.254.074-.34.073-.074.166-.194.246-.287s.114-.167.167-.273c.053-.114.027-.207-.013-.287s-.374-.893-.507-1.227c-.133-.32-.273-.28-.373-.286h-.32a.6.6 0 0 0-.44.206c-.147.167-.574.567-.574 1.38 0 .814.594 1.6.674 1.707.08.113 1.166 1.78 2.82 2.493.393.174.7.274.94.347.393.127.753.107 1.04.067.32-.047.98-.4 1.113-.787.14-.387.14-.713.093-.787-.046-.073-.146-.106-.313-.186"
                  />
                </svg>
              </button>
            </CircularIcon>
          </div>
        </div>

        <div className={styles['info']}>
          <p>{t('createdOn')}</p>
          <p>{leadDetails.source_created_at}</p>
        </div>
        <div className={styles['info']}>
          <p>{t('leadChannel')}</p>
          <p>{leadChannel[leadDetails.channel_id]}</p>
        </div>
        <div className={styles['info']}>
          <p>{t('ambassadorName')}</p>
          <p>Sarah Nagaty</p>
        </div>
        <div className={styles['info']}>
          <p>{t('expirationDate')}</p>
          <p>{leadDetails.lead_expiration_date}</p>
        </div>
      </div>
    </div>
  );
}

export default DealInfoCard;
