import { NavyButton } from '@orascom/common-components';
import InitialMeeting from '../initial-meeting/initial-meeting';
import LeadFeedback from '../lead-feedback/lead-feedback';
import PipelineType from '../pipeline-type/pipeline-type';
import UnitCategory from '../unit-category/unit-category';
import styles from './lead-stages.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import ScheduledMeetings from '../deal-scheduled-meetings/deal-scheduled-meetings';
import LeadActivityLog from '../lead-activity-log/lead-activity-log';
import DealClose from '../deal-close/deal-close';
import SubmitFeedback from '../editable-feedback/submit-feedback';
import { Objections } from '../objections/objections';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import {
  LeadDiscoveryInterface,
  PipelineDetails,
} from '../../definitions/interfaces/lead-stages.interface';
import { DealStatusEnum } from '../../definitions/interfaces/deals.interface';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { useState } from 'react';

interface LeadDetailsProps {
  setRefetchDeal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDeal: boolean;
  destinationSlug?: string;
  pipelineSourceId?: string;
  dealId: string;
  pipelineDetails?: PipelineDetails;
}

export function LeadDicovery(props: Readonly<LeadDetailsProps>) {
  const { t } = useTranslation();
  const initialValues: LeadDiscoveryInterface = {
    lead_source_id: props.dealId,
    pipeline_type: 0,
    destination_slug: '',
    sales_first_contact_date: '',
    unit_category_id: 0,
    feedback_id: 0,
    sales_comment: '',
    more_details: '',
    issue: null,
    lost_competitor_source_id: '',
    further_details: '',
    competitor_advantage: '',
    expected_budget: null,
    currency: '',
    future_budget_availability: null,
    future_opportunity: '',
    clarify_reasons: '',
    follow_update: '',
    expected_date: '',
    define_issue: null,
    concern: null,
    desired_product: null,
  };

  const validationSchema = yup.object({
    pipeline_type: yup
      .number()
      .required(t('required'))
      .moreThan(0, t('pleaseChooseAnOption')),
    sales_first_contact_date: yup.string().required(t('required')),
    unit_category_id: yup
      .number()
      .required(t('required'))
      .moreThan(0, t('pleaseChooseAnOption')),
    feedback_id: yup
      .number()
      .required(t('required'))
      .moreThan(0, t('pleaseChooseAnOption')),
    sales_comment: yup.string().required(t('required')),

    more_details: yup.string().when('feedback_id', {
      is: 5,
      then: yup.string().required(t('required')),
      otherwise: yup.string(),
    }),
    issue: yup
      .number()
      .nullable()
      .when('feedback_id', {
        is: 5,
        then: yup.number().required(t('required')).moreThan(0, t('required')),
        otherwise: yup.number().nullable(),
      }),
    lost_competitor_source_id: yup.string().when('feedback_id', {
      is: 6,
      then: yup.string().required(t('required')),
      otherwise: yup.string(),
    }),
    further_details: yup.string().when('feedback_id', {
      is: 6,
      then: yup.string().required(t('required')),
      otherwise: yup.string(),
    }),
    competitor_advantage: yup
      .number()
      .nullable()
      .when('feedback_id', {
        is: 6,
        then: yup.number().required(t('required')).moreThan(0, t('required')),
        otherwise: yup.number().nullable(),
      }),

    expected_budget: yup.string().when('feedback_id', {
      is: 7,
      then: yup.string().required(t('required')),
      otherwise: yup.string(),
    }),
    currency: yup.string().when('feedback_id', {
      is: 7,
      then: yup.string().required(t('required')),
      otherwise: yup.string(),
    }),
    future_budget_availability: yup
      .boolean()
      .nullable()
      .when('feedback_id', {
        is: 7,
        then: yup.boolean().required(t('required')),
        otherwise: yup.boolean().nullable(),
      }),
    future_opportunity: yup.boolean().when('feedback_id', {
      is: 7 | 9,
      then: yup.boolean().required(t('required')),
      otherwise: yup.boolean(),
    }),
    clarify_reasons: yup.string().when('feedback_id', {
      is: 14,
      then: yup.string().required(t('required')),
      otherwise: yup.string(),
    }),
  });

  const handleSubmit = (values: LeadDiscoveryInterface) => {
    const data = {
      lead_source_id: props.dealId,
      pipeline_type: values.pipeline_type,
      destination_slug: 'makadi-heights',
      sales_first_contact_date: values.sales_first_contact_date,
      unit_category_id: values.unit_category_id,
      feedback_id: values.feedback_id,
      sales_comment: values.sales_comment,
      more_details: values.more_details,
      issue: values.issue,
      lost_competitor_source_id: values.lost_competitor_source_id,
      further_details: values.further_details,
      competitor_advantage: values.competitor_advantage,
      expected_budget: values.expected_budget,
      currency: values.currency,
      future_budget_availability: values.future_budget_availability,
      future_opportunity: values.future_opportunity,
      clarify_reasons: values.clarify_reasons,
      follow_update: values.follow_update,
      expected_date: values.expected_date,
      define_issue: values.define_issue,
      concern: values.concern,
      desired_product: values.desired_product,
    };

    DealUtils.createLeadDiscoveryPipeline(data)
      .then(() => props.setRefetchDeal(!props.setRefetchDeal))
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ touched, errors, setFieldValue }) => (
        <Form className={styles['discovery-sections']}>
          <PipelineType setFieldValue={setFieldValue} />
          <hr className={styles['line']} />
          <LeadFeedback setFieldValue={setFieldValue} />
          <hr className={styles['line']} />
          <InitialMeeting
            formikProps={{
              touched,
              errors,
              setFieldValue,
            }}
            dealStatus={DealStatusEnum.LEAD_DISCOVERY}
          />
          <hr className={styles['line']} />
          <UnitCategory setFieldValue={setFieldValue} />
          <div className={styles['navy-btn']}>
            <NavyButton type="submit">UPDATE PIPELINE</NavyButton>
          </div>
          <hr className={styles['line']} />
          <Link
            to={ROUTES['DisqualifyLead'].getPath?.('1')}
            className={styles['disqualify-link']}
          >
            <p> {t('disqualifyLead')}</p>
          </Link>
        </Form>
      )}
    </Formik>
  );
}

export function SalesPitch(props: Readonly<LeadDetailsProps>) {
  const { t } = useTranslation();
  const [refetchActivityLog, setRefetchActivityLog] = useState(true);

  if (props.pipelineSourceId && props.destinationSlug)
    return (
      <div className={styles['discovery-sections']}>
        <ScheduledMeetings
          pipelineSourceId={props.pipelineSourceId}
          destinationSlug={props.destinationSlug}
          dealStatus={DealStatusEnum.SALES_PITCH}
          refetchDeal={props.refetchDeal}
          setRefetchDeal={props.setRefetchDeal}
        />
        <hr className={styles['line']} />
        <InitialMeeting
          dealStatus={DealStatusEnum.SALES_PITCH}
          meetingDate={props.pipelineDetails?.activity_date}
        />
        <hr className={styles['line']} />
        <SubmitFeedback
          refetchDeal={props.refetchDeal}
          setRefetchDeal={props.setRefetchDeal}
          pipelineSourceId={props.pipelineSourceId}
          destinationSlug={props.destinationSlug}
          refetchActivityLog={refetchActivityLog}
          setRefetchActivityLog={setRefetchActivityLog}
        />
        <hr className={styles['line']} />
        <LeadActivityLog
          pipelineSourceId={props.pipelineSourceId}
          refetchActivityLog={refetchActivityLog}
        />
        <hr className={styles['line']} />
        <Link
          to={ROUTES['DisqualifyLead'].getPath?.('1')}
          className={styles['disqualify-link']}
        >
          <p> {t('disqualifyLead')}</p>
        </Link>
      </div>
    );
}

export function LeadClosing(props: Readonly<LeadDetailsProps>) {
  const { t } = useTranslation();
  const [refetchActivityLog, setRefetchActivityLog] = useState(true);

  if (props.pipelineSourceId && props.destinationSlug)
    return (
      <div className={styles['discovery-sections']}>
        <DealClose
          pipelineSourceId={props.pipelineSourceId}
          destinationSlug={props.destinationSlug}
          refetchDeal={props.refetchDeal}
          setRefetchDeal={props.setRefetchDeal}
        />
        <hr className={styles['line']} />
        <ScheduledMeetings
          pipelineSourceId={props.pipelineSourceId}
          destinationSlug={props.destinationSlug}
          dealStatus={DealStatusEnum.DEAL_CLOSING}
          refetchDeal={props.refetchDeal}
          setRefetchDeal={props.setRefetchDeal}
        />
        <hr className={styles['line']} />
        <Objections objectionReason={props.pipelineDetails?.objection_name} />
        <hr className={styles['line']} />
        <SubmitFeedback
          refetchDeal={props.refetchDeal}
          setRefetchDeal={props.setRefetchDeal}
          pipelineSourceId={props.pipelineSourceId}
          destinationSlug={props.destinationSlug}
          refetchActivityLog={refetchActivityLog}
          setRefetchActivityLog={setRefetchActivityLog}
        />
        <hr className={styles['line']} />
        <LeadActivityLog
          pipelineSourceId={props.pipelineSourceId}
          refetchActivityLog={refetchActivityLog}
        />
        <hr className={styles['line']} />
        <Link
          to={ROUTES['DisqualifyLead'].getPath?.('1')}
          className={styles['disqualify-link']}
        >
          <p> {t('disqualifyLead')}</p>
        </Link>
      </div>
    );
}

export default [LeadFeedback, SalesPitch, LeadClosing];
