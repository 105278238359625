import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import { Sales as SalesInterface } from '../definitions/interfaces/sales.interface';

export class Sale {
  public static getSales(
    viewNumber: number
  ): Promise<Payload<Payload<SalesInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getSales.url(
        viewNumber
      )}`,
      {
        method: ENDPOINTS.getSales.method,
      }
    );
  }
}
