import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './my-customers.module.scss';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { ROUTES } from '../../api/routes';
// import { renderLeadStatusIcon } from '../../utils/deal.utils';
import { format } from 'date-fns';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { Customer } from '../../utils/customer';
import { Customers as CustomersInterface } from '../../definitions/interfaces/customers.interface';
import { Loader } from '@orascom/common-components';

export function MyCustomers() {
  const { t } = useTranslation();

  const [customersData, setCustomersData] = useState<CustomersInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = customersData.slice(indexOfFirstItem, indexOfLastItem);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Customer.getCustomers(1)
      .then((res) => {
        setCustomersData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myCustomers')}</h1>
      {loading ? (
        <Loader />
      ) : currentItems.length ? (
        <div className="analytics-table">
          <table>
            <thead>
              <tr className="manager-tr">
                <th className="manager-th">{t('fullName')}</th>
                <th className="manager-th">{t('phoneNumber')}</th>
                <th className="manager-th">{t('email')}</th>
                <th className="manager-th">{t('modifiedOn')}</th>
                <th className="manager-th">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item) => {
                const date = new Date(item.source_updated_at);
                const formattedDate = format(date, 'dd MMMM yyyy');
                return (
                  <tr className="manager-tr sales-man-tr" key={uuidv4()}>
                    <td className="manager-td--name ">{item.owner_name}</td>
                    <td className="sales-man-td">{item.phone}</td>
                    <td
                      className="sales-man-td"
                      style={{ wordWrap: 'break-word' }}
                    >
                      {item.email}
                    </td>
                    <td className="sales-man-td">{formattedDate}</td>
                    {/* <td className="manager-td--status sales-man-td">
                    {renderLeadStatusIcon(item.leadStatus)} 
                  </td>*/}
                    <td className="sales-man--link">
                      <Link to={ROUTES['MyCustomers'].path}>
                        {t('customerDetails')}{' '}
                        <img src={arrowIcon} alt="arrow" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t('noCustomersToDisplay')}</p>
      )}
      {currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > customersData.length
                ? customersData.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{customersData.length} </span>
            {t('sales')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={customersData.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MyCustomers;
