import { OptionValue } from '@orascom/api-interfaces';

export const statusesOptions = [
  {
    label: 'lead discovery',
    value: 'lead discovery',
  },
  {
    label: 'sales pitch',
    value: 'sales pitch',
  },
  {
    label: 'deal closing',
    value: 'deal closing',
  },
];
export enum DealSourceEnum {
  DIRECT = 'Direct',
  DIGITAL = 'Digital',
  BROKER = 'Broker',
  AMBASSADOR = 'Ambassador',
}

export enum FeedbackEnum {
  INERESTED = 'Interested',
  FOLLOW_UP = 'Follow Up',
  NO_ANSWER = 'No Answer',
}

export interface DealCreationResponse {
  customer_source_id: string;
  customer_inquiry: number;
  destination_source_id: string;
  email: string;
  first_name: string;
  mobile: string;
  interestedin_source_id: string;
  last_name: string;
  lead_source_id: string;
  channel_id: number;
  feedback_id: number;
  portal_comments: string;
  preferred_communication_method: string;
  preferred_communication_time: string;
}
export interface DealInterface {
  logical_name: string;
  channel: string;
  owner: string;
  lead_source_id: string;
  phone: string;
  name: string;
  sales_feedback: FeedbackEnum;
  lead_source: DealSourceEnum | null;
  lead_expiration_status: string;
  lead_expiration_date: string;
  source_created_at: string;
  ambassador: string;
  broker_name: string;
  tlscsr_comment: string;
  followup_date: string;
  last_contacted_date: string;
  last_engagement_date: string;
}
export enum DealStatusEnum {
  LEAD_DISCOVERY = 'Lead Discovery',
  SALES_PITCH = 'Sales Pitch',
  DEAL_CLOSING = 'Deal Closing',
}

export enum LeadStatus {
  LEAD_DISCOVERY = 'Lead Discovery',
  SALES_PITCH = 'Sales Pitch',
  DEAL_CLOSING = 'Deal Closing',
}
export interface OptionalFields {
  logical_name: string;
  source_created_at: string;
}

export interface WalkIn extends OptionalFields {
  office_source_id: string;
  office_name: string;
}

export interface Competition extends OptionalFields {
  competitor_source_id: string;
  competitor_name: string;
}

export interface DesiredUnit extends OptionalFields {
  unit_source_id: string;
  project_name: string;
  currency: string;
  unit_price: string;
  unit_type: string;
  unit_name: string;
  building_status: string;
  destination_name: string;
  unit_category: string;
}

export interface Ambassador extends OptionalFields {
  ambassador_source_id: string;
  ambassador_name: string;
}

export interface Broker extends OptionalFields {
  broker_source_id: string;
  state_code: string;
  phone: string;
  broker_name: string;
  account_manager_name: string;
  broker_type: string;
}

export interface Event extends OptionalFields {
  event_source_id: string;
  event_name: string;
}

export interface Exhibition extends OptionalFields {
  exhibition_source_id: string;
  exhibition_name: string;
}

export interface NewDealFormInterface {
  first_name: string;
  last_name: string;
  phone: string;
  phone_2?: string;
  phone_3?: string;
  email: string;
  channel_id: string;
  event_source_id: string;
  ambassador_source_id: string;
  description: string;
  broker_source_id: string;
  office_source_id: string;
  exhibition_source_id: string;
  feedback_id?: number;
  preferred_communication_method: string;
  preferred_communication_time: string;
  budget?: null;
  portal_comments: string;
  destination_slug: string;
  customer: string;
  referral_customer_name: string;
  referral_unit_destination: string;
  unit_number: string;
  customer_inquiry: string;
}

export interface NewDealInputInterface {
  firstName: string;
  lastName: string;
  email: string;
  unitName: string;
  unitType?: string;
  contactType?: OptionValue | null;
  clientBudget?: string;
  contactTime?: OptionValue | null;
  additionalInfo?: string;
  destination: OptionValue | null;
  phoneNumbers: { countryCode: string; phone: string }[];
  leadChannel: OptionValue | null;
  feedback: OptionValue | null;
  ambassadorName: OptionValue | null;
  description: string;
  walkInName: OptionValue | null;
  brokerName: OptionValue | null;
  eventsName: OptionValue | null;
  exhibitionName: OptionValue | null;
  customer: string;
  referralCustomerName: string;
  referralUnitDestination: string;
  unitNumber: string;
  customerInquiry: OptionValue | null;
}

export interface Meetings {
  logical_name: string;
  meeting_source_id: string;
  meeting_date: string;
  meeting_location: null;
  pipeline_source_id: string;
  lead_name: string;
  meeting_name: string;
}

export interface UpdatePipeline {
  lead_source_id: string;
  destination_slug: string;
  feedback_id: number;
  sales_comment: string;
  objection: boolean;
  objection_id?: number;
  objection_notes?: string;
  unit_source_id?: string;
  closure_date?: string;
  competitor_source_id?: string;
}

export interface UpdatePipelineInputInterface {
  objection?: boolean;
  objectionReason?: OptionValue | null;
  objectionNotes?: string;
  competitor?: OptionValue | null;
  desiredUnit?: OptionValue | null;
  selectedDate?: string;
}
export interface Feedback {
  logical_name: string;
  owner_id: string;
  feedback_source_id: string;
  feedback_title: string;
  feedback: string;
  pipeline_title: string;
  source_updated_at: string;
  status: string;
}
