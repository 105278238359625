import EditIcon from '../../assets/icons/edit.svg';
import styles from './objections.module.scss';
import { useTranslation } from 'react-i18next';

export function Objections(props: Readonly<{ objectionReason?: string }>) {
  const { t } = useTranslation();

  return (
    <div className={styles['objections']}>
      <div className={styles['header']}>
        <p className={styles['title']}>{t('objections')}</p>
        {/* <button className={styles['edit']}>
          <span>
            <img src={EditIcon} alt="" role="presentation" />
          </span>
          {t('edit').toUpperCase()}
        </button> */}
      </div>
      <div className={styles['meeting-date']}>
        <p>{t('reason')}:</p>
        <p>{props.objectionReason}</p>
      </div>
    </div>
  );
}

export default Objections;
