import { SelectDropdown } from '@orascom/common-components';
import styles from './unit-category.module.scss';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'formik';
import { FormikPassedStateProps } from '../../definitions/interfaces/lead-stages.interface';

export function UnitCategory({ setFieldValue }: FormikPassedStateProps) {
  const { t } = useTranslation();

  return (
    <div className={styles['unit-category']}>
      <p className={styles['title']}>{t('unitCategory')}</p>
      <div className={styles['select-category']}>
        <label htmlFor="unit_category_id">{t('selectUnitCategory')}</label>
        <SelectDropdown
          name="unit_category_id"
          options={[
            { label: t('singleFamily'), value: 1 },
            { label: t('multiFamily'), value: 2 },
            { label: t('commercial'), value: 3 },
            { label: t('fera'), value: 4 },
            { label: t('land'), value: 5 },
          ]}
          placeholder={t('selectDate')}
          className={styles['select']}
          onChange={(value) => setFieldValue('unit_category_id', value?.value)}
        />
        <ErrorMessage
          name="unit_category_id"
          component="div"
          className={styles['error']}
        />
      </div>
    </div>
  );
}

export default UnitCategory;
