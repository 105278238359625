import { handleError } from '@orascom/utils';
import { Customer as CustomerAPI } from '../api/customers';
import { Customers as CustomerInterface } from '../definitions/interfaces/customers.interface';
import { Payload } from '@orascom/api-interfaces';

export class Customer {
  public static getCustomers(
    viewNumber: number
  ): Promise<Payload<Payload<CustomerInterface[]>>> {
    return CustomerAPI.getCustomers(viewNumber)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
