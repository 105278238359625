import { handleError } from '@orascom/utils';
import { Sale as SaleAPI } from '../api/sales';
import { Sales as SalesInterface } from '../definitions/interfaces/sales.interface';
import { Payload } from '@orascom/api-interfaces';

export class Sales {
  public static getSales(
    viewNumber: number
  ): Promise<Payload<Payload<SalesInterface[]>>> {
    return SaleAPI.getSales(viewNumber)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
