import { SelectDropdown } from '@orascom/common-components';
import styles from './lead-feedback.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FormikPassedStateProps } from '../../definitions/interfaces/lead-stages.interface';
import { ErrorMessage, Field } from 'formik';
import { feedbackOptions } from '../../utils/deal.utils';

export function LeadFeedback({ setFieldValue }: FormikPassedStateProps) {
  const { t } = useTranslation();
  const [selectedFeedback, setSelectedFeedback] = useState({
    label: '',
    value: 0,
  });
  const [, setFutureOpportunity] = useState({
    label: '',
    value: 0,
  });
  const [, setFutureBudgetAvailability] = useState({
    label: '',
    value: 0,
  });

  useEffect(() => {
    if (selectedFeedback.label !== t('paymentTerms')) {
      setFieldValue('more_details', '');
      setFieldValue('issue', null);
    }

    if (selectedFeedback.label !== t('competition')) {
      setFieldValue('lost_competitor_source_id', '');
      setFieldValue('further_details', '');
      setFieldValue('competitor_advantage', null);
    }

    if (selectedFeedback.label !== t('price_concern')) {
      setFieldValue('expected_budget', '');
      setFieldValue('currency', '');
      setFieldValue('future_budget_availability', null);
    }

    if (selectedFeedback.label !== t('others')) {
      setFieldValue('clarify_reasons', '');
    }
  }, [selectedFeedback]);

  return (
    <div className={styles['lead-feedback']}>
      <p className={styles['title']}>{t('leadFeedback')}</p>
      <div className={styles['feedback-input']}>
        <div>
          <label>{t('feedback')}</label>
          <SelectDropdown
            name="feedback_id"
            options={feedbackOptions}
            placeholder={t('selectPlaceholder')}
            className={styles['select']}
            onChange={(value) => {
              setSelectedFeedback({
                label: value!.label,
                value: value!.value as number,
              });
              setFieldValue('feedback_id', value?.value);
            }}
          />
          <ErrorMessage
            name="feedback_id"
            component="div"
            className={styles['error']}
          />
        </div>
        <div>
          <label htmlFor="sales_comment">{t('yourFeedback')}</label>
          <Field
            className={styles['feedback-text']}
            type="text"
            name="sales_comment"
            placeholder={t('feedbackPlaceholder')}
          />
          <ErrorMessage
            name="sales_comment"
            component="div"
            className={styles['error']}
          />
        </div>
        {selectedFeedback.label === t('paymentTerms') && (
          <>
            <div>
              <label htmlFor="issue">{t('issue')}</label>
              <Field
                className={styles['feedback-text-small']}
                name="issue"
                type="number"
                placeholder={t('noOfIssues')}
              />
              <ErrorMessage
                name="issue"
                component="div"
                className={styles['error']}
              />
            </div>
            <div>
              <label htmlFor="more_details">{t('moreDetails')}</label>
              <Field
                className={styles['feedback-text']}
                name="more_details"
                type="text"
                placeholder={t('enterDetails')}
              />
              <ErrorMessage
                name="more_details"
                component="div"
                className={styles['error']}
              />
            </div>
          </>
        )}
        {selectedFeedback.label === t('competition') && (
          <>
            <div>
              <label htmlFor="lost_competitor_source_id">
                {t('lostcompetitorid')}
              </label>
              <Field
                name="lost_competitor_source_id"
                className={styles['feedback-text']}
                type="text"
                placeholder={t('feedbackPlaceholder')}
              />
              <ErrorMessage
                name="lost_competitor_source_id"
                component="div"
                className={styles['error']}
              />
            </div>
            <div>
              <label htmlFor="further_details">{t('furtherDetails')}</label>
              <Field
                name="further_details"
                className={styles['feedback-text']}
                type="text"
                placeholder={t('feedbackPlaceholder')}
              />
              <ErrorMessage
                name="further_details"
                component="div"
                className={styles['error']}
              />
            </div>
            <div>
              <label htmlFor="competitor_advantage">
                {t('competitorAdvantage')}
              </label>
              <Field
                name="competitor_advantage"
                className={styles['feedback-text-small']}
                type="number"
                placeholder={t('enterAdvantage')}
              />
              <ErrorMessage
                name="competitor_advantage"
                component="div"
                className={styles['error']}
              />
            </div>
          </>
        )}
        {selectedFeedback.label === t('priceConcern') && (
          <>
            <div>
              <label htmlFor="expected_budget">{t('expectedbudget')}</label>
              <Field
                name="expected_budget"
                className={styles['feedback-text-small']}
                type="number"
                placeholder={t('addExcpectedBudget')}
              />
              <ErrorMessage
                name="expected_budget"
                component="div"
                className={styles['error']}
              />
            </div>
            <div>
              <label htmlFor="currency">{t('currency')}</label>
              <Field
                name="currency"
                className={styles['feedback-text-small']}
                type="text"
                placeholder={t('addCurrency')}
              />
              <ErrorMessage
                name="currency"
                component="div"
                className={styles['error']}
              />
            </div>
            <div>
              <label htmlFor="future_budget_availability">
                {t('futurebudgetavailability')}
              </label>
              <SelectDropdown
                name="future_budget_availability"
                options={[
                  { label: t('yes'), value: 1 },
                  { label: t('no'), value: 0 },
                ]}
                placeholder={t('yes/no')}
                className={styles['select']}
                onChange={(value) => {
                  setFutureBudgetAvailability({
                    label: value!.label,
                    value: value!.value as number,
                  });
                  setFieldValue('future_budget_availability', value?.value);
                }}
              />
              <ErrorMessage
                name="future_budget_availability"
                component="div"
                className={styles['error']}
              />
            </div>
          </>
        )}
        {(selectedFeedback.label === t('priceConcern') ||
          selectedFeedback.label === t('productConcern')) && (
          <div>
            <label htmlFor="future_opportunity">{t('futureopportunity')}</label>
            <SelectDropdown
              name="future_opportunity"
              options={[
                { label: t('yes'), value: 1 },
                { label: t('no'), value: 0 },
              ]}
              placeholder={t('yes/no')}
              className={styles['select']}
              onChange={(value) => {
                setFutureOpportunity({
                  label: value!.label,
                  value: value!.value as number,
                });
              }}
            />
            <ErrorMessage
              name="future_opportunity"
              component="div"
              className={styles['error']}
            />
          </div>
        )}
        {selectedFeedback.label === t('others') && (
          <div>
            <label htmlFor="clarify_reasons">{t('clarifyreasons')}</label>
            <Field
              name="clarify_reasons"
              className={styles['feedback-text']}
              type="text"
              placeholder={t('feedbackPlaceholder')}
            />
            <ErrorMessage
              name="clarify_reasons"
              component="div"
              className={styles['error']}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LeadFeedback;
