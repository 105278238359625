const endpointsPrefix = process.env['NX__SALESMAN_ENDPOINT_PREFIX'] ?? '';
export const ENDPOINTS = {
  listSalesManDestinations: {
    url: `${endpointsPrefix}/destinations`,
    method: 'GET',
  },
  getSalesManDestinationInfo: {
    url: (destinationSlug: string) =>
      `${endpointsPrefix}/destinations/${destinationSlug}`,
    method: 'GET',
  },
  getSalesManDestinationLocations: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/locations`,
    method: 'GET',
  },
  getSalesManDestinationFacilities: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/facilities`,
    method: 'GET',
  },
  getSalesManDestinationProjects: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/projects`,
    method: 'GET',
  },
  getSalesManDestinationFAQ: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/faqs`,
    method: 'GET',
  },
  getSalesManFilters: {
    url: `${endpointsPrefix}/filters`,
    method: 'GET',
  },
  getSalesManUnits: {
    url: (filters?: string): string =>
      `${endpointsPrefix}/units${filters ?? ''}`,
    method: 'GET',
  },
  addSalesManSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'POST',
  },

  deleteSalesManSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'DELETE',
  },
  compareUnits: {
    path: `${endpointsPrefix}/units/compare`,
    method: 'GET',
  },

  getSalesManUnitDetails: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}`,
    method: 'GET',
  },

  getSalesManUnitPaymentTerms: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/payment-terms`,
    method: 'GET',
  },
  downloadSalesManOmanSalesOffer: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/sales-offer`,
    method: 'GET',
  },
  createLead: {
    url: `${endpointsPrefix}/leads`,
    method: 'POST',
  },
  createLeadDiscoveryPipeline: {
    url: `${endpointsPrefix}/pipelines`,
    method: 'POST',
  },
  getLeadDetails: {
    url: (id: string) => `${endpointsPrefix}/leads/${id}`,
    method: 'GET',
  },
  getPipelineDetails: {
    url: (leadId: string) =>
      `${endpointsPrefix}/pipelines?lead_source_id=${leadId}&view_number=1`,
    method: 'GET',
  },
  getFeedbackList: {
    url: `${endpointsPrefix}/ambassadors?view_number=1`,
    method: 'GET',
  },
  getAmbassadorsList: {
    url: `${endpointsPrefix}/ambassadors?view_number=1`,
    method: 'GET',
  },
  getBrokerList: {
    url: `${endpointsPrefix}/brokers?view_number=1`,
    method: 'GET',
  },
  getEventsList: {
    url: `${endpointsPrefix}/events?view_number=1`,
    method: 'GET',
  },
  getCompetitionList: {
    url: `${endpointsPrefix}/competitions?view_number=1`,
    method: 'GET',
  },
  getExhibitionsList: {
    url: `${endpointsPrefix}/exhibitions?view_number=1`,
    method: 'GET',
  },
  getWalkInList: {
    url: `${endpointsPrefix}/offices?view_number=1`,
    method: 'GET',
  },
  getLeads: {
    url: (viewNumber: number) =>
      `${endpointsPrefix}/leads?view_number=${viewNumber}`,
    method: 'GET',
  },
  getSales: {
    url: (viewNumber: number) =>
      `${endpointsPrefix}/sales?view_number=${viewNumber}`,
    method: 'GET',
  },
  getCustomers: {
    url: (viewNumber: number) =>
      `${endpointsPrefix}/customers?view_number=${viewNumber}`,
    method: 'GET',
  },
  createMeeting: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}/meetings`,
    method: 'POST',
  },
  listMeetings: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}/meetings`,
    method: 'GET',
  },
  updateDealPipeline: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}`,
    method: 'PATCH',
  },
  listFeedback: {
    url: (pipelineSourceId: string) =>
      `${endpointsPrefix}/pipelines/${pipelineSourceId}/feedback`,
    method: 'GET',
  },
  listDesiredUnit: {
    url: `${endpointsPrefix}/crm-units?view_number=1`,
    method: 'GET',
  },
};
