import { useEffect, useRef, useState } from 'react';
import styles from './deal-details-breadcrumbs.module.scss';
import { DealStatusEnum } from '../../definitions/interfaces/deals.interface';
import { useTranslation } from 'react-i18next';

interface DealDetailsBreadcrumbsProps {
  dealStatus: DealStatusEnum;
}

type StylesOptions = 'inactive' | 'active';

interface BreadCrumbsStylesInterface {
  lead_discovery: StylesOptions;
  sales_pitch: StylesOptions;
  deal_closing: StylesOptions;
}

export function DealDetailsBreadcrumbs({
  dealStatus,
}: Readonly<DealDetailsBreadcrumbsProps>) {
  const [breadCrumbsStyles, setBreadCrumbsStyles] =
    useState<BreadCrumbsStylesInterface>();
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    switch (dealStatus) {
      case DealStatusEnum.DEAL_CLOSING:
        setBreadCrumbsStyles({
          deal_closing: 'active',
          lead_discovery: 'active',
          sales_pitch: 'active',
        });
        break;

      case DealStatusEnum.LEAD_DISCOVERY:
        setBreadCrumbsStyles({
          deal_closing: 'inactive',
          lead_discovery: 'active',
          sales_pitch: 'inactive',
        });
        break;

      case DealStatusEnum.SALES_PITCH:
        setBreadCrumbsStyles({
          deal_closing: 'inactive',
          lead_discovery: 'active',
          sales_pitch: 'active',
        });
        break;

      default:
        break;
    }
  }, [dealStatus]);

  return (
    <div className={styles['wrapper']} ref={breadcrumbsRef}>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.lead_discovery}`]
        }`}
      >
        <h4>{t('leadDiscovery')}</h4>
        <p>{t('leadDicoveryDescription')}</p>
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.sales_pitch}`]
        }`}
      >
        <h4>{t('salesPitch')}</h4>
        <p>{t('salesPitchDescription')}</p>
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.deal_closing}`]
        }`}
      >
        <h4>{t('dealClosing')}</h4>
        <p>{t('dealClosingDescription')}</p>
      </button>
    </div>
  );
}

export default DealDetailsBreadcrumbs;
